var OUTGOING_KEPLER_MESSAGE_TYPE;
(function (OUTGOING_KEPLER_MESSAGE_TYPE) {
    OUTGOING_KEPLER_MESSAGE_TYPE["EXIT"] = "exit";
    OUTGOING_KEPLER_MESSAGE_TYPE["LOADED"] = "loaded";
    OUTGOING_KEPLER_MESSAGE_TYPE["TTS_SPEAK"] = "ttsSpeak";
})(OUTGOING_KEPLER_MESSAGE_TYPE || (OUTGOING_KEPLER_MESSAGE_TYPE = {}));
var INCOMING_KEPLER_MESSAGE_TYPE;
(function (INCOMING_KEPLER_MESSAGE_TYPE) {
    INCOMING_KEPLER_MESSAGE_TYPE["SET_CONFIG"] = "setConfig";
    INCOMING_KEPLER_MESSAGE_TYPE["DEVICE_INFO"] = "getDeviceInfo";
    INCOMING_KEPLER_MESSAGE_TYPE["DEEP_LINK"] = "getDeeplinkParam";
    INCOMING_KEPLER_MESSAGE_TYPE["REMOTE_BUTTON_PRESS"] = "remoteButtonPress";
    INCOMING_KEPLER_MESSAGE_TYPE["CONTENT_LAUNCHER"] = "contentLauncher";
})(INCOMING_KEPLER_MESSAGE_TYPE || (INCOMING_KEPLER_MESSAGE_TYPE = {}));
export { OUTGOING_KEPLER_MESSAGE_TYPE, INCOMING_KEPLER_MESSAGE_TYPE };
