import { Language } from '@lightningjs/sdk';
import getVodAttributes, { getResumeAttributes } from '../attributes/getVodAttributes';
import { setDefaultValue } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import { ClosedCaptionsUtils } from '../../../ClosedCaptions/ClosedCaptionsUtils';
import { getSmartTileAttributes } from './getSmartTileAttributes';
const getVideoStartAttributes = (params) => {
    const { smartTile, shelf, ccLanguage, previousVideo, previousVideoType, resumeTime, durationSinceLaunch, } = params;
    return setDefaultValue({
        ...getVodAttributes(params, ccLanguage, previousVideo, previousVideoType),
        ...getResumeAttributes({ resumeTime }),
        ...getSmartTileAttributes(smartTile),
        'Episode Credits Left': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Content Position': shelf === null || shelf === void 0 ? void 0 : shelf.tileIndex,
        'Custom Shelf Position': shelf === null || shelf === void 0 ? void 0 : shelf.shelfIndex,
        'Custom Shelf Title': shelf === null || shelf === void 0 ? void 0 : shelf.listTitle,
        Casting: MPARTICLE_DEFAULT_ATTR_VALUE,
        Sponsor: shelf === null || shelf === void 0 ? void 0 : shelf.sponsorName,
        'CC Language': ClosedCaptionsUtils.getAnalyticsCCLanguage(ccLanguage),
        'Playlist Name': shelf === null || shelf === void 0 ? void 0 : shelf.playlistMachineName,
        'Playlist Position': shelf === null || shelf === void 0 ? void 0 : shelf.playlistPosition,
        'Custom Shelf Type': (shelf === null || shelf === void 0 ? void 0 : shelf.shelfType) || 'Regular',
        'Shelf Machine Name': shelf === null || shelf === void 0 ? void 0 : shelf.machineName,
        'Video Duration': params.durationInMilliseconds,
        Language: params.language || Language.get(),
        'Duration Since Launch': durationSinceLaunch,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getVideoStartAttributes;
