import { Launch } from '@lightningjs/sdk';
import App from './App';
import './moment';
import TVPlatform from './lib/tv-platform';
import settings from '../settings.json';
import metadata from '../metadata.json';
import { ENV, getInspectorFromUrl, getProxyPath, loadLightningInspector, migrateLocalStorage, } from './helpers';
import { DEFAULT_STAGE_STYLES } from './constants';
import { initializeDatadog } from './lib/Datadog';
import AppConfigFactorySingleton from './config/AppConfigFactory';
const { appSettings, platformSettings, appData = {}, } = settings || {};
const formatAppSettings = (appSettings) => ({
    ...appSettings,
    stage: { ...appSettings.stage, ...TVPlatform.getStageSettings() },
    keys: TVPlatform.getPlatformKeyMapping(),
    ...metadata,
});
const formatPlatformSettings = (platformSettings) => {
    /**
     * Production index.html doesn't have window.PROXY_PATH, so we use global PROXY_PATH
     */
    platformSettings.path = getProxyPath();
    if (NODE_ENV === ENV.PRODUCTION) {
        platformSettings.log = false;
        platformSettings.inspector = false;
    }
    return platformSettings;
};
const adaptedAppSettings = formatAppSettings(appSettings);
const adaptedPlatformSettings = formatPlatformSettings(platformSettings);
const initializeCanvas = () => {
    var _a;
    const app = Launch(App, adaptedAppSettings, adaptedPlatformSettings, appData);
    if (platformSettings.inspector || getInspectorFromUrl())
        loadLightningInspector();
    migrateLocalStorage();
    TVPlatform.init();
    const style = document.createElement('style');
    document.head.appendChild(style);
    (_a = style === null || style === void 0 ? void 0 : style.sheet) === null || _a === void 0 ? void 0 : _a.insertRule(DEFAULT_STAGE_STYLES);
    document.body.appendChild(app.stage.getCanvas());
};
// Override the TextTexture setter with a setter that has an input value check for null and undefined.
// @ts-expect-error TS2551: Property 'textures' does not exist on type 'Application<TemplateSpecLoose, TypeConfig>'. Did you mean 'texture'?
Object.defineProperty(window.lng.textures.TextTexture.prototype, 'text', {
    set(text) {
        text = typeof text !== 'undefined' && text !== null ? text : '';
        if (this._text !== text) {
            this._text = String(text);
            this._changed();
        }
    },
});
const initializeApp = async () => {
    // Wait for config initialization to complete
    await AppConfigFactorySingleton.get().getRemoteConfig();
    // Initialize datadog
    initializeDatadog();
    // Initialize canvas and fonts
    initializeCanvas();
    TVPlatform.initializeFonts();
};
// Start initialization sequence
initializeApp();
