import { Log, Lightning, Router } from '@lightningjs/sdk';
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore';
import { findCoreSDKPlayerComponent, setStreamData } from '../../store/PlayerStore/actions';
import { getLemonade } from '../../store/PlayerStore/actions/lemonade';
import VODCorePlayer from './VODCorePlayer';
import { MPARTICLE_ERROR_CONTEXTS, PlayerStates, ROUTE } from '../../constants';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { SecondaryPlayerSingleton } from '../../lib/SecondaryPlayer';
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader';
import { useRequest } from '../../lib/useRequest';
import { getVodStreamRequestConfig } from '../../helpers';
import { geoCheck } from '../../api/Live';
import { openErrorModal } from '../../widgets/Modals/errorModal/ErrorModal';
import ModalManager from '../../lib/ModalManager';
import AuthenticationSingleton from '../../authentication/Authentication';
import { PlayerError } from '../../components/error/PlayerError';
import { StreamLoaderErrors } from './StreamLoader/error';
const VOD_PLAYER_TAG = 'VOD Player';
export default class VODPlayer extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._onStoreEvent = async (event) => {
            switch (event.type) {
                case PlayerStoreEvents.STREAM_OK:
                    try {
                        const { payload } = await PlayerStoreSingleton.dispatch(getLemonade(event.payload.stream));
                        if (payload.errors) {
                            // Lemonade service not available
                            const errorPayload = {
                                error: {
                                    detail: PlayerError.LEMONADE,
                                    data: payload.errors.message,
                                },
                                stack: payload.errors.stack,
                            };
                            openErrorModal(
                            // @ts-expect-error Property 'authType' is missing in the payload
                            errorPayload, event.payload.stream, event.payload.program, false);
                            return;
                        }
                        const res = await fetch(payload.playbackUrl);
                        const isCoreVodDisabled = !AppConfigFactorySingleton.config.core_video_sdk.vod_enabled;
                        const manifest404 = !res.ok; // Some assets have a Lemonade entry but aren't reprocessed yet
                        if (manifest404 || isCoreVodDisabled) {
                            throw new Error(manifest404 ? 'Manifest 404 error' : 'Core VOD is disabled');
                        }
                        this.patch({
                            PlayerComponent: {
                                type: VODCorePlayer,
                                signals: { restartStream: this._onChanged.bind(this) },
                                params: this._params,
                            },
                        });
                    }
                    catch (error) {
                        Log.error('Error in _onStoreEvent:', error);
                        // If you want to propagate the error further up:
                        throw error;
                    }
                    break;
                default:
                    return;
            }
        };
    }
    set params(params) {
        this._params = params;
    }
    static _states() {
        return [
            class Ready extends this {
                _getFocused() {
                    return this.tag('PlayerComponent') || this;
                }
            },
        ];
    }
    _init() {
        openPlayerLoader();
    }
    _active() {
        this._subscription = new SubscriptionBuilder()
            .with(SubscriptionSources.PLAYER_STORE)
            .subscribe(this._onStoreEvent);
        this._fetchStream();
    }
    _inactive() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _detach() {
        this._clearPlayer();
        ModalManager.close();
    }
    _onChanged() {
        this._setState('');
        this._clearPlayer();
        this._fetchStream();
    }
    async _fetchStream() {
        var _a, _b, _c, _d;
        await SecondaryPlayerSingleton.destroy();
        const requestConfig = getVodStreamRequestConfig((_a = this._params) === null || _a === void 0 ? void 0 : _a.isOlympicsVideo, (_b = this._params) === null || _b === void 0 ? void 0 : _b.videoId);
        try {
            const data = await useRequest(requestConfig).fetch();
            if (!data)
                throw data;
            const geoCheckResponse = await geoCheck((_c = data === null || data === void 0 ? void 0 : data.metadata) === null || _c === void 0 ? void 0 : _c.channelId, undefined, {
                contentType: 'vod',
                failOnRestrictionError: false,
            });
            if (geoCheckResponse.restricted) {
                const openErrorModalPayload = {
                    error: {
                        detail: StreamLoaderErrors.GEO,
                        data: (_d = geoCheckResponse === null || geoCheckResponse === void 0 ? void 0 : geoCheckResponse.restrictionDetails) === null || _d === void 0 ? void 0 : _d.description,
                    },
                    stack: (new Error()).stack,
                };
                const CoreSDKPlayerComponent = findCoreSDKPlayerComponent(data === null || data === void 0 ? void 0 : data.sections);
                openErrorModal(
                // @ts-expect-error Property 'authType' is missing in the payload
                openErrorModalPayload, CoreSDKPlayerComponent.CoreSDKPlayerData.player, CoreSDKPlayerComponent.analytics, false);
            }
            else {
                await PlayerStoreSingleton.dispatch(setStreamData(data));
            }
        }
        catch (error) {
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                code: VOD_PLAYER_TAG,
                description: 'unable to load stream data',
                payload: error,
            });
            Router.navigate(ROUTE.error);
        }
    }
    _clearPlayer() {
        this.patch({
            PlayerComponent: undefined,
        });
    }
    _getPrefetchErrorAnalytics(errorEvent, error, stream) {
        return {
            video: {
                ...stream,
                currentTime: 0,
            },
            errorType: 'Video',
            description: errorEvent.error.data,
            friendlyMessage: error.message.replace('%{brand}', stream.brandDisplayTitle),
            code: errorEvent.error.detail,
            mvpd: AuthenticationSingleton.getMvpdData(),
            source: MPARTICLE_ERROR_CONTEXTS.vod,
            system: 'Other',
            stack: errorEvent.stack,
            fatal: true,
            isLive: false,
        };
    }
    $onAttached() {
        this._setState(PlayerStates.Ready);
    }
    _updateActiveCues(cues) {
        var _a, _b;
        (_b = (_a = this.tag('PlayerComponent')) === null || _a === void 0 ? void 0 : _a._updateActiveCues) === null || _b === void 0 ? void 0 : _b.call(_a, cues);
    }
}
