const ARROW_UP = 'Up';
const ARROW_DOWN = 'Down';
const ARROW_LEFT = 'Left';
const ARROW_RIGHT = 'Right';
const BACKSPACE = 'Back';
const ENTER = 'Enter';
const EXIT = 'Exit';
const RETURN = 'Return';
const MEDIA_PAUSE = 'MediaPause';
const MEDIA_PLAY = 'MediaPlay';
const MEDIA_PLAY_PAUSE = 'MediaPlayPause';
const MEDIA_FAST_FORWARD = 'MediaFastForward';
const MEDIA_REWIND = 'MediaRewind';
const MEDIA_STOP = 'MediaStop';
const MEDIA_PLAY_BACK = 'XF86PlayBack';
const MEDIA_TRACK_NEXT = 'MediaTrackNext';
const MEDIA_TRACK_PREVIOUS = 'MediaTrackPrevious';
const VOLUME_UP = 'VolumeUp';
const VOLUME_DOWN = 'VolumeDown';
const VOLUME_MUTE = 'VolumeMute';
const PREV_CH = 'PageDown';
const ALT = 'Alt';
const CONTROL = 'Control';
const SHIFT = 'Shift';
const INFO = 'Info';
const GAMEPAD_MENU = 'GamepadMenu';
const GAMEPAD_VIEW = 'GamepadView';
const Keys = {
    ARROW_UP,
    ARROW_DOWN,
    ARROW_LEFT,
    ARROW_RIGHT,
    BACKSPACE,
    ENTER,
    EXIT,
    RETURN,
    MEDIA_PAUSE,
    MEDIA_PLAY,
    MEDIA_PLAY_PAUSE,
    MEDIA_PLAY_BACK,
    MEDIA_FAST_FORWARD,
    MEDIA_REWIND,
    MEDIA_STOP,
    MEDIA_TRACK_NEXT,
    MEDIA_TRACK_PREVIOUS,
    VOLUME_UP,
    VOLUME_DOWN,
    VOLUME_MUTE,
    PREV_CH,
    ALT,
    CONTROL,
    SHIFT,
    INFO,
    GAMEPAD_MENU,
    GAMEPAD_VIEW,
};
const xbox = {
    A: 195,
    B: 196,
    X: 'GamepadX',
    LeftTrigger: 201,
    RightTrigger: 202,
    Up: 203,
    Down: 204,
    Left: 205,
    Right: 206,
    LeftThumbUp: 211,
    LeftThumbDown: 212,
    LeftThumbRight: 213,
    LeftThumbLeft: 214,
    GamepadRightShoulder: 208,
    GamepadLeftShoulder: 207,
    GamepadView: 'GamepadView',
    GamepadMenu: 'GamepadMenu',
};
const vizio = {
    Exit: 27,
    Left: 37,
    Up: 38,
    Right: 39,
    Down: 40,
    Pause: 19,
    Rewind: 412,
    Stop: 413,
    Play: 415,
    FastForward: 417,
    MediaTrackNext: 418,
    MediaTrackPrevious: 419,
    AudioVolumeMute: 173,
    AudioVolumeDown: 174,
    AudioVolumeUp: 175,
    Info: 157,
};
const kepler = {
    Back: 27,
    FastForward: 228,
    PlayPause: 179,
    Rewind: 227,
};
const KeyCodes = {
    xbox,
    vizio,
    kepler,
};
export { Keys, KeyCodes };
