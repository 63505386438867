import { sendMetric } from '../../../../lib/analytics/Analytics';
import { EVENTS } from '../../../../lib/analytics/types';
import { AnalyticsDelegate } from './AnalyticsDelegate';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils';
export class AnalyticsWithEndCardDelegate extends AnalyticsDelegate {
    _getEndCardData() {
        return {
            video: {
                ...PlayerStoreSingleton.program,
                ...this._getAnalyticsData(),
                authType: this._ctx.authtype,
            },
            ccLanguage: ClosedCaptionsUtils.getCCType(),
        };
    }
    fireEndCard(payload = {}) {
        sendMetric(EVENTS.END_CARD, {
            ...this._getEndCardData(),
            ...payload,
        });
    }
    fireEndCardImpression(payload = {}) {
        sendMetric(EVENTS.END_CARD_IMPRESSION, {
            ...this._getEndCardData(),
            ...payload,
        });
    }
}
