import { Language, Router, Utils } from '@lightningjs/sdk';
import BaseComponent from '../../components/base';
import { getMpid } from '../../helpers';
import version from '../../version';
import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../constants';
import TVPlatform from '../../lib/tv-platform';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
export default class ContactUs extends BaseComponent {
    static _template() {
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: 810,
            rect: true,
            color: COLORS.dark5,
            PageTitleHolder: {
                x: 100,
                y: 78,
                Icon: {
                    h: 40,
                    w: 40,
                    src: Utils.asset('images/settings/contact_us.png'),
                },
                Title: {
                    x: 60,
                    text: {
                        text: Language.translate('contact_us'),
                        fontSize: 30,
                        fontFace: FONT_FACE.regular,
                    },
                },
            },
            Content: {
                y: 175,
                x: 233,
                flex: { direction: FLEX_DIRECTION.column },
                Title: {
                    text: {
                        text: Language.translate('here_to_help'),
                        fontFace: FONT_FACE.light,
                        fontSize: 54,
                    },
                },
                SubTitle: {
                    flexItem: { marginTop: -10 },
                    TextHolder: {
                        flex: { direction: FLEX_DIRECTION.row },
                        MailInfo: {
                            text: {
                                text: Language.translate('email_to'),
                                fontFace: FONT_FACE.light,
                                fontSize: 30,
                            },
                        },
                        Email: {
                            flexItem: { marginLeft: 5 },
                            text: {
                                text: AppConfigFactorySingleton.config.contactUsAddress,
                                fontFace: FONT_FACE.semiBold,
                                fontSize: 30,
                            },
                        },
                    },
                },
                Line: {
                    flexItem: { marginTop: 105 },
                    rect: true,
                    color: COLORS.mediumGray3,
                    h: 1,
                    w: 768,
                },
                Information: {
                    flexItem: { marginTop: 65 },
                    text: {
                        text: Language.translate('following_information'),
                        fontFace: FONT_FACE.regular,
                        fontSize: 30,
                    },
                },
                SupportInformation: {
                    flex: {
                        direction: FLEX_DIRECTION.column,
                        paddingTop: 20,
                        paddingBottom: 20,
                    },
                },
            },
            Version: {
                flex: { direction: FLEX_DIRECTION.column },
                y: 700,
                x: 233,
                text: {
                    text: `v${version}`,
                    fontFace: FONT_FACE.regular,
                    fontSize: 18,
                },
            },
        };
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
    }
    _active() {
        this.widgets.menu.collapse({
            subCategoryItem: Language.translate('contact_us'),
            hideProfile: true,
        });
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    _handleBack(e) {
        e.preventDefault();
        Router.navigate(ROUTE.settings);
    }
    _setTranslation() {
        const title = Language.translate('contact_us');
        const contentTitle = Language.translate('here_to_help');
        const information = Language.translate('following_information');
        const contact = Language.translate('email_to');
        const address = AppConfigFactorySingleton.config.contactUsAddress;
        const supportInformation = [
            {
                title: Language.translate('your_device'),
                value: TVPlatform.deviceId,
            },
            {
                title: Language.translate('device_model_#'),
                value: TVPlatform.deviceType,
            },
            {
                title: Language.translate('support_id'),
                value: getMpid() || '',
            },
        ];
        this.patch({
            PageTitleHolder: { Title: { text: { text: title } } },
            Content: {
                Title: { text: { text: contentTitle } },
                SubTitle: {
                    TextHolder: {
                        MailInfo: { text: { text: contact } },
                        Email: { text: { text: address } },
                    },
                },
                Information: {
                    text: {
                        text: information,
                    },
                },
                SupportInformation: supportInformation.reduce((acc, info, index) => ({
                    ...acc,
                    [`InfoHolder${index}`]: {
                        flex: { direction: FLEX_DIRECTION.row },
                        [`InfoTitle${index}`]: {
                            text: {
                                text: `${info.title} : `,
                                fontFace: FONT_FACE.semiBold,
                                fontSize: 30,
                            },
                        },
                        [`InfoValue${index}`]: {
                            text: {
                                text: info.value,
                                fontFace: FONT_FACE.light,
                                fontSize: 30,
                            },
                        },
                    },
                }), {}),
            },
        });
        const supportInfoSpeech = supportInformation.flatMap((supportInfo) => [
            supportInfo.title.replace('#', ''),
            supportInfo.value,
        ]);
        this.announce = [
            title,
            contentTitle,
            contact,
            address,
            information,
            ...supportInfoSpeech,
            `v${version}`,
        ];
    }
}
