import { Lightning, Language, Registry, Utils } from '@lightningjs/sdk';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import BasePlayerControls from './BasePlayerControls';
import { AdControlsStateFactory } from './states/AdControls';
import { MediaOptionsStateFactory } from './states/MediaOptions';
import { MoreInfoStateFactory } from './states/MoreInfo';
import { QOSPanelStateFactory } from './states/QOSPanel';
import { formatSecondsAsHhMmSSForTTS, formatTime, isProduction, setSmooth } from '../../../helpers';
import TextButton from '../../buttons/TextButton';
import { COLORS, FONT_FACE, PlayerControlsStates, TEXT_ALIGN, TRICK_PLAY_ACTIONS, TRICK_PLAY_ELEMENTS, } from '../../../constants';
import { KeyMap } from '../../../lib/KeyMap';
import Announcer from '../../../lib/tts/Announcer';
import { useProgress } from './hooks/useProgress';
export default class SeekablePlayerControlsV1 extends BasePlayerControls {
    constructor() {
        super(...arguments);
        this._descriptionHeight = 0;
        this._isDescriptionShown = false;
        this._direction = 0;
        this._isScrubInProgress = false;
        this._keyMap = KeyMap(this, [
            [
                [PlayerControlsStates.QOSPanel, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.MediaOptions, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.TempPass, PlayerControlsStates.QOSPanel],
            ],
            [[], PlayerControlsStates.AdControls, []],
            [[], PlayerControlsStates.PlayOrPause, []],
        ]);
        this._announceScrubEnd = debounce(() => {
            this._isScrubInProgress = false;
            const currentTime = this._progress.value[0];
            Registry.setTimeout(() => Announcer.announce([TRICK_PLAY_ACTIONS.pause, formatSecondsAsHhMmSSForTTS(currentTime)], {
                append: true,
            }), 0);
        }, 1000);
        this._announceScrubStart = throttle(() => {
            const currentTime = this._progress.value[0];
            Announcer.announce(Language.translate(this._direction > 0 ? 'fastforward' : 'rewind', formatSecondsAsHhMmSSForTTS(currentTime)));
        }, 5000);
    }
    set seekIcon(seek) {
        this._setControlIcon(seek === 1 ? 'images/player/ff.png' : 'images/player/rew.png');
    }
    set seekIconV2(seek) {
        var _a, _b, _c, _d;
        (_a = this.tag('SeekBar')) === null || _a === void 0 ? void 0 : _a.patch({
            BigCircle: undefined,
            SmallCircle: undefined,
            Metadata: undefined,
            Shader: undefined,
        });
        switch (seek) {
            case 0:
                (_b = this.tag('SeekBar')) === null || _b === void 0 ? void 0 : _b.patch({
                    Shader: {
                        rect: true,
                        w: 24,
                        h: 24,
                        x: 0,
                        y: 0,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            strokeColor: COLORS.white,
                            fillColor: COLORS.red,
                            stroke: 3,
                            radius: 12,
                        },
                    },
                });
                break;
            case -1:
            case -2:
            case -3:
            case 1:
            case 2:
            case 3: {
                const isRew = seek < 0;
                const direction = isRew ? -1 : 1;
                const start = isRew ? 0 : seek;
                const end = 3 - Math.abs(seek);
                const fill = Array(end).fill(0);
                const arrows = [1, 1, 1];
                arrows.splice(start, end, ...fill);
                (_c = this.tag('SeekBar')) === null || _c === void 0 ? void 0 : _c.patch({
                    Shader: {
                        rect: true,
                        w: 94,
                        h: 32,
                        x: 0,
                        y: -8,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            fillColor: COLORS.red,
                            stroke: 0,
                            radius: 16,
                        },
                    },
                    Metadata: {
                        Arrows: {
                            x: isRew ? -30 : -23,
                            y: 0,
                            ...arrows.reduce((acc, type, index) => ({
                                ...acc,
                                [`Arrow${index}`]: {
                                    src: Utils.asset('images/player/arrow.png'),
                                    w: 17,
                                    h: 15,
                                    x: 17 * index,
                                    color: type === 1 ? COLORS.white : COLORS.mediumGray4,
                                    scaleX: direction,
                                },
                            }), {}),
                        },
                        Time: {
                            y: -50,
                            color: COLORS.white,
                            mountX: 0.5,
                            text: {
                                fontSize: 28,
                                fontFace: FONT_FACE.regular,
                                textAlign: TEXT_ALIGN.center,
                            },
                        },
                    },
                });
                break;
            }
            case 10:
            case -10:
                (_d = this.tag('SeekBar')) === null || _d === void 0 ? void 0 : _d.patch({
                    Metadata: {
                        Arrow: {
                            y: -25,
                            mount: 0.5,
                            src: Utils.asset('images/player/jump.png'),
                            w: 30,
                            h: 34,
                            scaleX: seek < 0 ? 1 : -1,
                        },
                        Time: {
                            y: -31,
                            color: COLORS.white,
                            mountX: 0.5,
                            text: {
                                fontSize: 12,
                                fontFace: FONT_FACE.bold,
                                textAlign: TEXT_ALIGN.center,
                                text: 10,
                            },
                        },
                    },
                    Shader: {
                        rect: true,
                        w: 24,
                        h: 24,
                        x: 0,
                        y: 0,
                        mountX: 0.5,
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            strokeColor: COLORS.white,
                            fillColor: COLORS.red,
                            stroke: 3,
                            radius: 12,
                        },
                    },
                });
                break;
            default:
                break;
        }
    }
    updateSeekBarTimerV2() {
        var _a;
        // TODO: move this into separate components once we use V2 controls for VOD and FER
        const [time, _, range] = useProgress().value;
        if (range) {
            const text = formatTime(Date.now() / 1000 - (range[1] - time));
            (_a = this.tag('Metadata.Time')) === null || _a === void 0 ? void 0 : _a.patch({
                text: {
                    text,
                },
            });
        }
    }
    _enable() {
        if (this._getState() === PlayerControlsStates.AdControls ||
            this._getState() === PlayerControlsStates.SkipButton ||
            this._getState() === PlayerControlsStates.MoreInfo)
            return;
        this._setState(PlayerControlsStates.PlayOrPause);
    }
    get seekingAllowed() {
        return true;
    }
    _init() {
        var _a, _b, _c, _d;
        super._init();
        (_b = (_a = this.tag('PlayerControls.SettingsContainer')) === null || _a === void 0 ? void 0 : _a.childList) === null || _b === void 0 ? void 0 : _b.addAt({
            ref: 'MoreInfo',
            h: 63,
            flexItem: {},
            type: TextButton,
            radius: 31,
            fontSize: 32,
            fontFace: FONT_FACE.regular,
            label: Language.translate('more_info'),
            focusFontColor: COLORS.black,
            unfocusFontColor: COLORS.black5,
            focusBackGroundColor: COLORS.white,
            unfocusBackgroundColor: COLORS.mediumGray3,
            autoWidth: true,
            padding: 22,
        }, 0);
        (_d = (_c = this.tag('Description')) === null || _c === void 0 ? void 0 : _c.on) === null || _d === void 0 ? void 0 : _d.call(_c, 'txLoaded', () => {
            this._descriptionHeight = this.tag('Description').renderHeight;
        });
    }
    setMoreInfoState() {
        this._setState(PlayerControlsStates.MoreInfo);
    }
    _showDescription() {
        this._isDescriptionShown = !this._isDescriptionShown;
        const titleContainerY = this._isDescriptionShown
            ? this.getTitleContainerY() - this._descriptionHeight
            : this.getTitleContainerY();
        const descriptionAlpha = this._isDescriptionShown ? 1 : 0.01;
        setSmooth(this.tag('TitleContainer'), 'y', titleContainerY, {
            duration: 0.3,
        });
        setSmooth(this.tag('Description'), 'alpha', descriptionAlpha, {
            duration: 0.3,
        });
    }
    // #region Key handler
    _handleMediaRewind(e) {
        if (this._getState() === PlayerControlsStates.AdControls)
            return;
        if (this._getState() !== PlayerControlsStates.Seeking)
            this._setState(PlayerControlsStates.Seeking);
        this._seek(-1);
    }
    _handleMediaFastForward(e) {
        if (this._getState() === PlayerControlsStates.AdControls)
            return;
        if (this._getState() !== PlayerControlsStates.Seeking)
            this._setState(PlayerControlsStates.Seeking);
        this._seek(1);
    }
    _handleInfo() {
        if (!isProduction())
            this.fireAncestors('$seekToTheEnd');
    }
    // #endregion
    // #region Seeking
    _seek(direction) {
        this.seekIcon = direction;
        this._direction = direction;
        this.fireAncestors(direction > 0 ? '$forward' : '$rewind');
        if (!this._isScrubInProgress) {
            this._isScrubInProgress = true;
            this._announceScrubStart();
        }
        this._announceScrubEnd();
    }
    _onSeekingStarted() { }
    _onSeekingEnded() { }
    onPlayAnnounce() {
        this._announce([
            TRICK_PLAY_ACTIONS.playSeekable,
            formatSecondsAsHhMmSSForTTS(this._progress.value[0]),
        ]);
    }
    onPauseAnnounce() {
        const [currentTime, duration] = this._progress.value;
        this._announce([
            TRICK_PLAY_ACTIONS.pauseSeekable,
            formatSecondsAsHhMmSSForTTS(currentTime),
            TRICK_PLAY_ACTIONS.seconds_out,
            formatSecondsAsHhMmSSForTTS(duration),
        ]);
    }
    // #endregion
    static _states() {
        return [
            class PlayOrPause extends this {
                $enter() {
                    if (this.active) {
                        this._stateAnnounce();
                    }
                }
                _active() {
                    super._active();
                    this._stateAnnounce();
                }
                _handleEnter() {
                    this.fireAncestors('$playOrPause');
                }
                _handleRight() {
                    if (this.seekingAllowed)
                        this._setState(PlayerControlsStates.Seeking, [1]);
                }
                _handleLeft() {
                    if (this.seekingAllowed)
                        this._setState(PlayerControlsStates.Seeking, [-1]);
                }
                _stateAnnounce() {
                    const isPlaying = this.fireAncestors('$isPlaying');
                    this.playOrPause = isPlaying;
                    this._announce(isPlaying ? TRICK_PLAY_ELEMENTS.pauseButton : TRICK_PLAY_ELEMENTS.playButton);
                }
            },
            class Seeking extends this {
                $enter(_, seekDelta) {
                    this._seek(seekDelta);
                    this.fireAncestors('$startSeeking');
                    setSmooth(this.tag('BigCircle'), 'alpha', 1);
                    this._onSeekingStarted();
                }
                $exit() {
                    setSmooth(this.tag('BigCircle'), 'alpha', 0);
                    this.fireAncestors('$endSeeking');
                    this._onSeekingEnded();
                }
                /**
                 * MediaPlay key handler for Seeking state
                 * Uses the same behavior as when you press enter in the Seeking state
                 * instead of just calling the players play() method.
                 * @private
                 */
                _handleMediaPlay() {
                    this._handleEnter();
                }
                /**
                 * MediaPlayPause key handler for Seeking state
                 * Since playback is already paused in the seeking state,
                 * we'll just continue playback using the same behavior logic as MediaPlay and Enter
                 */
                _handleMediaPlayPause() {
                    this._handleEnter();
                }
                _handleEnter() {
                    this.fireAncestors('$seek');
                    if (this._getState() !== PlayerControlsStates.AdControls)
                        this._setState(PlayerControlsStates.PlayOrPause);
                }
                _handleRight() {
                    this._seek(1);
                }
                _handleLeft() {
                    this._seek(-1);
                }
            },
            QOSPanelStateFactory(this),
            MediaOptionsStateFactory(this),
            AdControlsStateFactory(this),
            MoreInfoStateFactory(this),
        ];
    }
}
