import { Language, Router, Storage, Utils } from '@lightningjs/sdk';
import { updateLanguage } from './moment';
import routerConfig from './routerConfig';
import Loader from './components/loader/Loader';
import { ConfirmationDialog, ExitDialog, Menu, Notification } from './widgets';
import Styler from './lib/Styler';
import style from './lib/style';
import AppConfigFactorySingleton from './config/AppConfigFactory';
import AnalyticsSingleton from './lib/analytics/Analytics';
import TVPlatform from './lib/tv-platform';
import { AudioLanguageTag, getBrandName } from './helpers';
import { BRAND, COLORS, LANGUAGES, ROUTE, SCREEN_SIZE, STORAGE_KEYS } from './constants';
import { PlatformSubscriptionType } from './lib/tv-platform/base';
import RouterUtil from './util/RouterUtil';
import { ModalManagerEventTypes } from './lib/ModalManager';
import { SubscriptionBuilder, SubscriptionSources } from './util/SubscriptionBuilder';
import { loadModals, createModal, updateModal } from './widgets/Modals';
import Announcer from './lib/tts/Announcer';
import { AppStateManager } from './lib/AppStateManager';
import { ACTIVATION_TYPE } from './widgets/Modals/activation/constants';
import LaunchDarklySingleton from './lib/launchDarkly/LaunchDarkly';
import { notifyUser } from './widgets/Modals/activation/notification';
import { ConsentManagement } from './lib/cmp/ConsentManagement';
import { getAccessToken } from './api/Identity';
import { setUser } from './lib/Datadog';
import { getMpid } from './helpers';
export default class App extends Router.App {
    constructor() {
        super(...arguments);
        this._onVoiceControlEvent = (e) => {
            if (!e.entity)
                return Router.navigate(e.route);
            RouterUtil.navigateToRoute(e.route, { entity: e.entity.entityType, value: e.entity.value });
        };
        this._onTTSEvent = (value) => {
            Announcer.enabled = value;
        };
        this._onModalEvent = (evt) => {
            return {
                [ModalManagerEventTypes.CLOSE]: () => {
                    var _a;
                    (_a = this._modal) === null || _a === void 0 ? void 0 : _a.destroy(evt.focusPage);
                },
                [ModalManagerEventTypes.OPEN]: () => {
                    this._modal = createModal(this, evt.type, evt.payload);
                },
                [ModalManagerEventTypes.UPDATE]: () => {
                    if (this._modal)
                        updateModal(this, evt.type, evt.payload);
                },
            }[evt.event]();
        };
        this._handleForceButtonPress = (keyCode) => {
            const keyboardEvent = new KeyboardEvent('keydown', {
                bubbles: true,
                cancelable: true,
                shiftKey: false,
                altKey: false,
                keyCode: keyCode,
            });
            document.dispatchEvent(keyboardEvent);
        };
        this._onAuthEvent = async (type) => {
            await LaunchDarklySingleton.updateUserAuthContext(true);
            if (type !== ACTIVATION_TYPE.TEMPPASS)
                await notifyUser(type);
            if (getAccessToken()) {
                await ConsentManagement.getOneTrustToken();
            }
        };
    }
    static getFonts() {
        return [];
    }
    _focusChange() {
        Announcer.onFocusChange(this.application.focusPath);
    }
    _setup() {
        Styler.compile(style);
        routerConfig.events = {
            navigation: () => {
                this.tag('Menu').patch({ currentButton: Router.getActiveHash() });
            },
        };
        // Set user's mpid for Datadog monitoring
        setUser({
            id: getMpid(),
        });
        Router.startRouter(routerConfig);
        AppConfigFactorySingleton.get().events.subscribe((config) => this._onAppConfigLoaded(config));
        if (Language.get() !== Storage.get(STORAGE_KEYS.APP_LANGUAGE)) {
            Language.set(Storage.get(STORAGE_KEYS.APP_LANGUAGE));
        }
        if (!Storage.get(STORAGE_KEYS.PREFERRED_AUDIO_LANG)) {
            Storage.set(STORAGE_KEYS.PREFERRED_AUDIO_LANG, AudioLanguageTag.en);
        }
        updateLanguage(Language.get());
    }
    _onAppConfigLoaded(config) {
        const lang = config.preferredLanguage;
        if (lang) {
            Storage.set(STORAGE_KEYS.APP_LANGUAGE, lang);
            Language.set(lang);
            updateLanguage(lang);
        }
    }
    _handleBack(e) {
        Router.back();
        e.preventDefault();
        e.stopPropagation();
    }
    _firstActive() {
        this._subscription = new SubscriptionBuilder()
            .with({
            type: SubscriptionSources.TV_PLATFORM,
            event: PlatformSubscriptionType.VOICE_CONTROL,
            handler: this._onVoiceControlEvent,
        }, {
            type: SubscriptionSources.TV_PLATFORM,
            event: PlatformSubscriptionType.VOICE,
            handler: this._onTTSEvent,
        }, {
            type: SubscriptionSources.MODAL,
            handler: this._onModalEvent,
        }, {
            type: SubscriptionSources.AUTHENTICATION,
            handler: this._onAuthEvent,
        })
            .subscribe();
        AppStateManager.init();
    }
    _inactive() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    /**
     * Exit key handler
     * @private
     */
    _handleExit() {
        this.$close();
    }
    _handleAppClose() {
        if (Router.getActiveRoute() === ROUTE.home) {
            this.$close();
        }
        else {
            Router.navigate(ROUTE.home);
        }
    }
    $close() {
        var _a;
        AnalyticsSingleton.onAppClosed();
        TVPlatform.exit();
        // @ts-expect-error TS2339: Property 'closeApp' does not exist on type 'Application<TemplateSpecLoose, TypeConfig>'.
        (_a = this.application) === null || _a === void 0 ? void 0 : _a.closeApp();
    }
    $exitToPeacock() {
        AnalyticsSingleton.onAppClosed();
        TVPlatform.exitToPeacock();
    }
    static language() {
        return {
            file: Utils.asset('translations/translations.json'),
            language: Storage.get(STORAGE_KEYS.APP_LANGUAGE) ||
                (getBrandName() !== BRAND.telemundo ? LANGUAGES.DEFAULT : LANGUAGES.SPANISH),
        };
    }
    static _template() {
        return {
            ...super._template(),
            Widgets: {
                Menu: {
                    type: Menu,
                    activeIndex: 0,
                    items: [],
                },
                Loader: {
                    visible: false,
                    type: Loader,
                    zIndex: 100,
                    y: 540,
                    mountY: 0.5,
                },
                // #region Modals start
                // Reserve 200 range zIndex for modals
                ...loadModals(),
                // #endregion
                Notification: {
                    type: Notification,
                    zIndex: 300,
                    visible: false,
                },
                ExitDialog: {
                    type: ExitDialog,
                    zIndex: 301,
                    visible: false,
                },
                ConfirmationDialog: {
                    type: ConfirmationDialog,
                    zIndex: 302,
                    visible: false,
                },
            },
            Loading: {
                rect: true,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                color: COLORS.dark,
                visible: false,
                zIndex: 99,
                Loader: {
                    y: 540,
                    mountY: 0.5,
                    type: Loader,
                },
            },
        };
    }
    $getWidget(name) {
        return this.tag(name);
    }
}
